import React from "react";
import { BrowserRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";
import "./App.scss";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Notfound from "./pages/Notfound";
import Blog from "./pages/Blog";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Route exact path="/" render={() => <Redirect to="/" />} />

      <Route
        render={({ location }) => (
          <PageTransition
            preset={"glueLeftFromRight"}
            enterAnimation={"rotateCubeBottomIn"}
            exitAnimation={"rotateCubeBottomOut"}
            transitionKey={location.pathname}
          >
            <Switch location={location}>
              <Route path="/" exact component={Home} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/blog" exact component={Blog} />
              <Route path="/terms" exact component={Terms} />
              <Route path="/privacy" exact component={Privacy} />
              <Route path="*" component={Notfound} />
            </Switch>
          </PageTransition>
        )}
      />
    </BrowserRouter>
  );
}

export default App;
