import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Icon from "react-feather";
import Layout from "../components/Layout";
import { Checkbox } from "primereact/checkbox";
import { toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";
import "primeflex/primeflex.css";

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
    phone: "",
    webdev: false,
    appdev: false,
    infra: false,
    ml: false,
    budget: null,
  });

  const submitHandler = async (event: any) => {
    event.preventDefault();
    let message;
    let error;
    if (!formdata.name) {
      error = true;
      message = "Name is required";
    } else if (!formdata.email) {
      error = true;
      message = "Email is required";
    } else if (!formdata.company) {
      error = true;
      message = "Company is required";
    } else if (!formdata.message) {
      error = true;
      message = "Message is required";
    } else if (!formdata.phone) {
      error = true;
      message = "Phone Number is required";
    } else if (!formdata.budget) {
      error = true;
      message = "Budget is required";
    } else {
      error = false;
      message = "You message has been sent!";
    }
    handleAlerts(error, message);
  };
  const handleChange = (event: any) => {
    console.log("EVENT DATA");
    console.log(event);
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = (error: any, message: any) => {
    if (error && message) {
      toast.error(message, {});
    } else if (!error && message) {
      toast.success(message);
      console.log("alert form data");
      console.log(formdata);
      axios
        .post("/api/email", {
          name: formdata.name,
          email: formdata.email,
          company: formdata.company,
          message: formdata.message,
          phone: formdata.phone,
          webdev: formdata.webdev,
          appdev: formdata.appdev,
          infra: formdata.infra,
          ml: formdata.ml,
          budget: formdata.budget,
        })
        .then((response) => {
          console.log("response email");
          console.log(response);
        });
    } else {
      return null;
    }
  };

  const handleCheckboxClick = (event: any) => {
    console.log("check box event");
    console.log(event);
    console.log(event.target.name);
    console.log(event.target.checked);
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.checked,
    });
    /*if (!disableInputIsChecked) {
      // this click was to check the box
    }
    setDisableInputIsChecked((prevValue) => !prevValue); // invert value*/
  };

  const handleRadioButton = (event: any) => {
    setFormdata({
      ...formdata,
      budget: event.value,
    });
    /*if (!disableInputIsChecked) {
      // this click was to check the box
    }
    setDisableInputIsChecked((prevValue) => !prevValue); // invert value*/
  };

  return (
    <Layout>
      <div className="mi-child-img-filter">
        <img src="/images/meeting.png" />
      </div>
      <div className="mi-child-hero">
        <h1 className="mi-child-hero-title">Get In Touch</h1>
        <div className="mi-child-hero-subtitle">We want to hear from you</div>
      </div>
      <div className="mi-contact-area mi-section">
        <h2 style={{ color: "#037fff", textAlign: "center" }}>
          Tell Us What You Need
        </h2>
        <div className="container">
          <div className="row">
            <div className="">
              <div className="mi-contact-formwrapper">
                <form
                  action="#"
                  className="mi-form mi-contact-form"
                  onSubmit={submitHandler}
                >
                  <div className="p-grid p-justify-center">
                    <div className="mi-form-field p-col-3">
                      <input
                        onChange={handleChange}
                        type="text"
                        name="name"
                        id="contact-form-name"
                        value={formdata.name}
                        placeholder="Name"
                      />
                    </div>
                    <div className="mi-form-field p-col-3">
                      <input
                        onChange={handleChange}
                        type="text"
                        name="email"
                        id="contact-form-email"
                        value={formdata.email}
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="p-grid p-justify-center">
                    <div className="mi-form-field p-col-3">
                      <input
                        onChange={handleChange}
                        type="text"
                        name="company"
                        id="contact-form-company"
                        value={formdata.company}
                        placeholder="Company"
                      />
                    </div>
                    <div className="mi-form-field p-col-3">
                      <input
                        onChange={handleChange}
                        type="text"
                        name="phone"
                        id="contact-form-phone"
                        value={formdata.phone}
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="p-grid p-justify-center p-mb-4 p-mt-2">
                    <div className="mi-form-field p-col-6">
                      <textarea
                        onChange={handleChange}
                        name="message"
                        id="contact-form-message"
                        cols="30"
                        rows="6"
                        value={formdata.message}
                        placeholder="Leave Us a Message"
                      ></textarea>
                    </div>
                  </div>
                  <div className="p-grid p-justify-center">
                    <div className="mi-contact-form-title p-mt-4 p-mb-4">
                      <h5>Work Desired</h5>
                    </div>
                  </div>
                  <div className="mi-contact-form-checkboxes p-grid p-justify-center">
                    <div className="p-field-checkbox p-col-6 p-sm-5 p-md-3">
                      <Checkbox
                        inputId="webdev"
                        name={"webdev"}
                        checked={formdata.webdev}
                        onChange={handleCheckboxClick}
                      ></Checkbox>
                      <label htmlFor="webdev" className="p-checkbox-label">
                        Web Development
                      </label>
                    </div>
                    <div className="p-field-checkbox p-col-6 p-sm-5 p-md-3">
                      <Checkbox
                        inputId="appdev"
                        name={"appdev"}
                        checked={formdata.appdev}
                        onChange={handleCheckboxClick}
                      ></Checkbox>
                      <label htmlFor="appdev" className="p-checkbox-label">
                        App Development
                      </label>
                    </div>
                    <div className="p-field-checkbox p-col-6 p-sm-5 p-md-3">
                      <Checkbox
                        inputId="infra"
                        name={"infra"}
                        checked={formdata.infra}
                        onChange={handleCheckboxClick}
                      ></Checkbox>
                      <label htmlFor="infra" className="p-checkbox-label">
                        Scalable Infrastructure
                      </label>
                    </div>
                    <div className="p-field-checkbox p-col-6 p-sm-5 p-md-3">
                      <Checkbox
                        inputId="ml"
                        name={"ml"}
                        checked={formdata.ml}
                        onChange={handleCheckboxClick}
                      ></Checkbox>
                      <label htmlFor="ml" className="p-checkbox-label">
                        Machine Learning
                      </label>
                    </div>
                  </div>
                  <div className="p-grid p-justify-center">
                    <div className="mi-contact-form-title p-mt-4 p-mb-4">
                      <h5>Budget</h5>
                    </div>
                  </div>
                  <div className="p-grid p-justify-center mi-contact-form-radiobuttons">
                    <div className="p-field-radiobutton p-col-6 p-sm-5 p-md-3">
                      <RadioButton
                        name="budget"
                        value="1"
                        checked={formdata.budget === "1"}
                        onChange={handleRadioButton}
                      />
                      <label htmlFor="budget1">{"< $5,000"}</label>
                    </div>
                    <div className="p-field-radiobutton p-col-6 p-sm-5 p-md-3">
                      <RadioButton
                        name="budget"
                        value="2"
                        checked={formdata.budget === "2"}
                        onChange={handleRadioButton}
                      />
                      <label htmlFor="budget2">$5,000 - $10,000</label>
                    </div>
                    <div className="p-field-radiobutton p-col-6 p-sm-5 p-md-3">
                      <RadioButton
                        name="budget"
                        value="3"
                        checked={formdata.budget === "3"}
                        onChange={handleRadioButton}
                      />
                      <label htmlFor="budget2">$10,000 - $25,000</label>
                    </div>
                    <div className="p-field-radiobutton p-col-6 p-sm-5 p-md-3">
                      <RadioButton
                        name="budget"
                        value="4"
                        checked={formdata.budget === "4"}
                        onChange={handleRadioButton}
                      />
                      <label htmlFor="budget2">{"> $25,000"}</label>
                    </div>
                  </div>
                  <div className="p-grid p-justify-center">
                    <div className="mi-form-field">
                      <button className="mi-button" type="submit">
                        Send Mail
                        <i className="pi pi-envelope mi-header-menu-button-icon"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
