import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import { Button } from "primereact/button";
import LineIcon from "react-lineicons";

function Header() {
  const [information, setInformation] = useState({ brandImage: null });
  const [navigationToggler, setNavigationToggler] = useState(false);
  const [logoSize, setLogoSize] = useState(false);

  const handleNavigationToggler = () => {
    setNavigationToggler(!navigationToggler);
  };

  /*useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);*/

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setLogoSize(true);
      } else if (window.scrollY < 100) {
        setLogoSize(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
      <div className="mi-header-inner">
        <div className="mi-header-image">
          <Link to="/">
            <img src="/images/brand-image.png" alt="brandimage" />
          </Link>
        </div>
        <div className="mi-header-menu-container">
          <ul className="mi-header-menu">
            <li>
              <NavLink exact to="/">
                <span>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="/about">
                <span>About</span>
              </NavLink>
            </li>
            {/*<li>
              <NavLink to="/portfolio">
                <span>Portfolio</span>
              </NavLink>
            </li>*/}
            {/*<li>
              <NavLink to="/blog">
                <span>Blog</span>
              </NavLink>
            </li>*/}
            {/*<li>
              <NavLink to="/contact">
                <span>Contact</span>
              </NavLink>
            </li>*/}
          </ul>
          <div style={{ marginLeft: "1rem" }}>
            <NavLink to="/contact" className="mi-header-menu-button-link">
              <div className="p-button mi-header-menu-button">
                <div className="mi-header-menu-button-text">
                  Get In Touch
                  <i className="pi pi-comments mi-header-menu-button-icon"></i>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
