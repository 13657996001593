import React, { useState, useEffect } from "react";

function Footer() {
  return (
    <div className="mi-footer">
      <svg viewBox="0 0 1000 100">
        <polygon
          points="0,25 500, 100 0, 100"
          style={{ fill: "#000" }}
        ></polygon>
        <polygon
          points="1000,25 500, 100 1000, 100"
          style={{ fill: "#000" }}
        ></polygon>
        <polygon
          points="0,25 500, 75 1000, 25 500,100 0,25"
          style={{ fill: "#666666" }}
        ></polygon>
        <polygon
          points="0,25 500, 90 1000, 25 500,100 0,25"
          style={{ fill: "#323232" }}
        ></polygon>
      </svg>
      <div className="mi-footer-container">
        <div className="mi-footer-container-list p-grid">
          <div className="p-col mi-footer-container-list-col p-col-align-center">
            <h4>Office</h4>
            <ul>
              <li>17131 Denver St.</li>
              <li>Suite 12</li>
              <li>Detroit, MI 48224</li>
              <li>contact@codexr.io</li>
            </ul>
          </div>
          <div className="p-col mi-footer-container-list-col p-col-align-center">
            <h4>Features</h4>
            <ul>
              <li>
                <a href="https://reactnative.dev/">React Native</a>
              </li>
              <li>
                <a href="https://nodejs.org/en/">NodeJS</a>
              </li>

              <li>
                <a href="https://www.tensorflow.org/">Tensor Flow</a>
              </li>
              <li>
                <a href="https://kubernetes.io/">Kubernetes</a>
              </li>
            </ul>
          </div>
          <div className="p-col mi-footer-container-list-col p-col-align-center">
            <h4>Links</h4>
            <ul>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
              <li>
                <a href="/terms">Terms of Service</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        style={{
          color: "white",
          backgroundColor: "#000",
          display: "flex",
          justifyContent: "center",
          fontSize: 12,
          paddingBottom: "1rem"
        }}
      >
        Copyright © {new Date().getFullYear()} Codexr, LLC All rights reserved
      </div>
    </div>
  );
}

export default Footer;
