import React, { useState, useEffect } from "react";
import axios from "axios";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Layout from "../components/Layout";

function Blog() {
  const [toggler, setToggler] = useState(false);
  const [information, setInformation] = useState("");
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  return (
    <Layout>
      <div className="mi-child-img-filter">
        <img src="/images/detroit.png" />
      </div>
      <div className="mi-child-hero">
        <h1 className="mi-child-hero-title">BLOG TITLE</h1>
        <div className="mi-child-hero-subtitle">About Subtitle</div>
      </div>
      <div style={{ width: "80%", margin: "0 auto", color: "black" }}>
        <h2 style={{ color: "#037fff", textAlign: "center" }}>
          This is a new test
        </h2>
      </div>
    </Layout>
  );
}

export default Blog;
