import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Socialicons from "../components/Socialicons";
import Layout from "../components/Layout";
import { Card } from "primereact/card";
import { FaDatabase } from "react-icons/fa";
import { RiComputerFill, RiPieChart2Fill } from "react-icons/ri";
import { GoLightBulb, GoBrowser } from "react-icons/go";
import { FaPalette } from "react-icons/fa";
import { DiAndroid, DiApple } from "react-icons/di";
import {
  VictoryChart,
  VictoryLine,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryScatter,
} from "victory";

let data = [
  { x: "Q1 '15", y: 26.8 },
  { x: "Q2 '15",y: 23.78 },
  { x: "Q3 '15",  y: 28.7 },
  { x: "Q4 '15", y: 26.92 },
  { x: "Q1 '16",  y: 27.32 },
  { x: "Q2 '16",  y: 29.29 },
  { x: "Q3 '16", y: 32.85 },
  { x: "Q4 '16",  y: 35.31 },
  { x: "Q1 '17",  y: 37.77 },
  { x: "Q2 '17",  y: 40.2 },
  { x: "Q3 '17",  y: 43.05 },
  { x: "Q4 '17", y: 39.67 },
  { x: "Q1 '18", y: 39.95 },
  { x: "Q2 '18",  y: 41.61 },
  { x: "Q3 '18", y: 42.79 },
  { x: "Q4 '18",  y: 39.22 },
  { x: "Q1 '19",  y: 40.61 },
  { x: "Q2 '19",  y: 43.47 },
  { x: "Q3 '19",  y: 44.36 },
  { x: "Q4 '19",  y: 49.41 },
  { x: "Q1 '20",  y: 50.51 },
  { x: "Q2 '20", y: 48.76 },
];

const FancyButton = React.forwardRef((props, ref) => (
  <button ref={ref} className="FancyButton">
    {props.children}
  </button>
));

function Home() {
  const [information, setInformation] = useState({ aboutContent: null });
  const appleRef = useRef({ value: null });
  useEffect(() => {
    axios.get("/api/information").then((response) => {
      console.log("INFORMATOIONSSSS");
      console.log(information);
      setInformation(response.data);
    });
  }, []);

  useEffect(() => {
    if (typeof appleRef !== "undefined") {
      console.log("YOLO SWAG");
      console.log(appleRef);
      let value = appleRef.current.value;
      console.log(value);
    }
  }, []);

  /*if (!information.aboutContent) {
    return <div />;
  }*/
  return (
    <Layout>
      <div className="mi-home-vid-filter">
        <video
          className="mi-home-vid"
          autoPlay={true}
          loop={true}
          muted={true}
        >
          <source type="video/mp4" src="/video/finalvideo.mp4" />
        </video>
      </div>
      <div className="mi-home-hero">
        <div className="mi-home-hero-title">BUILD SOMETHING AMAZING</div>
        <div className="mi-home-hero-subtitle">
          Creating a fantastic experience for our clients
        </div>
      </div>

      <div className="mi-home-cards">
        <Card className="mi-home-card mi-home-top-card">
          <div id="circlewrap">
            <div>
              <div className="ball1"></div>
              <div className="ball2"></div>
              <div className="ball3"></div>
              <DiAndroid
                size={"3rem"}
                color={"#3DDC84"}
                className="mi-home-card-icon"
              />
            </div>
          </div>
          <div className="mi-home-card-title p-card-title">Android</div>
          <div>
            We use Android Studio, React Native, Java, Kotlin and Javascript to
            bring you powerful Android apps
          </div>
        </Card>
        <Card className="mi-home-card mi-home-card-apple mi-home-top-card">
          <svg
            stroke="currentColor"
            fill="url(#Pattern)"
            stroke-width="0"
            version="1.1"
            viewBox="0 0 32 32"
            color="black"
            className="mi-home-card-icon apple"
            height="3rem"
            width="3rem"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: "black" }}
          >
            <defs>
              <pattern id="Pattern" x="0" y="0" width="20" height="100">
                <rect x="0" y="0" width="20" height="10" fill="#61BB45"></rect>
                <rect x="0" y="10" width="20" height="3" fill="#FEB827"></rect>
                <rect x="0" y="13" width="20" height="3" fill="#F5821E"></rect>
                <rect x="0" y="16" width="20" height="3" fill="#E0393E"></rect>
                <rect x="0" y="19" width="20" height="3" fill="#963D97"></rect>
                <rect x="0" y="22" width="20" height="3" fill="#009FDB"></rect>
              </pattern>
            </defs>
            <path d="M23.023 17.093c-0.033-3.259 2.657-4.822 2.777-4.901-1.512-2.211-3.867-2.514-4.705-2.548-2.002-0.204-3.91 1.18-4.926 1.18-1.014 0-2.583-1.15-4.244-1.121-2.185 0.033-4.199 1.271-5.323 3.227-2.269 3.936-0.58 9.769 1.631 12.963 1.081 1.561 2.37 3.318 4.061 3.254 1.63-0.064 2.245-1.055 4.215-1.055s2.524 1.055 4.248 1.021c1.753-0.032 2.864-1.591 3.936-3.159 1.24-1.814 1.751-3.57 1.782-3.659-0.038-0.017-3.416-1.312-3.451-5.202zM19.783 7.53c0.897-1.089 1.504-2.602 1.34-4.108-1.294 0.053-2.861 0.86-3.79 1.948-0.832 0.965-1.561 2.502-1.365 3.981 1.444 0.112 2.916-0.734 3.816-1.821z"></path>
          </svg>
          <div className="mi-home-card-title p-card-title">iOS</div>
          <div>
            We create iOS apps through Xcode, Swift, Obj-C and Javascript
            programming languages, as well as React Native
          </div>
        </Card>
        <Card className="mi-home-card mi-home-card-web mi-home-top-card">
          <GoBrowser
            size={"3rem"}
            color={"#037fff"}
            className="mi-home-card-icon"
          />
          <div className="mi-home-card-title p-card-title">Web</div>
          <div>
            We develop responsive web front ends and backends using technologies
            like NodeJS, Django and React
          </div>
        </Card>
      </div>
      <div>
        <svg viewBox="0 0 1000 100">
          <polygon
            points="0,25 500, 100 0, 100"
            style={{ fill: "#037fff" }}
          ></polygon>
          <polygon
            points="1000,25 500, 100 1000, 100"
            style={{ fill: "#037fff" }}
          ></polygon>
          <polygon
            points="0,25 500, 75 1000, 25 500,100 0,25"
            style={{ fill: "#67b2ff" }}
          ></polygon>
          <polygon
            points="0,25 500, 90 1000, 25 500,100 0,25"
            style={{ fill: "#3598ff" }}
          ></polygon>
        </svg>
        <div
          className="mi-home-section"
          style={{
            backgroundColor: "#037fff",
          }}
        >
          <div
            className="mi-home-section-container"
            style={{
              color: "white",
            }}
          >
            <div className="mi-home-section-flex">
              <div style={{ display: "flex", alignSelf: "center" }}>
                <GoLightBulb className="mi-home-lightbulb mi-home-section-icon" />
              </div>
              <div>
                <h4 className="mi-home-section-title">USE CUTTING EDGE TECH</h4>
                <div style={{ display: "flex" }}>
                  We are using the latest technologies created and used by the
                  biggest players in the industry, including Google, Apple and
                  Amazon. We have created software for both enterprise
                  organizations, and the Federal government.
                </div>
              </div>
            </div>
          </div>
        </div>
        <svg viewBox="0 0 1000 100">
          <polygon
            points="0,0 500, 0 0, 75"
            style={{ fill: "#037fff" }}
          ></polygon>
          <polygon
            points="1000,0 500, 0 1000, 75"
            style={{ fill: "#037fff" }}
          ></polygon>
          <polygon
            points="0,75 500, 25 1000, 75 500,0 0,75"
            style={{ fill: "#67b2ff" }}
          ></polygon>
          <polygon
            points="0,75 500, 10 1000, 75 500,0 0,75"
            style={{ fill: "#3598ff" }}
          ></polygon>
        </svg>
      </div>
      <div className="mi-home-section mi-home-section-chart">
        <div className="mi-home-section-chart-body" style={{}}>
          We are masters at responsive design. Desktop, mobile or tablet - we
          will make your vision look good on all platforms.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontSize: "2rem",
              color: "#000",
              display: "flex",
              fontWeight: "bold",
            }}
          >
            Percentage of Mobile Device Traffic
          </div>
          <div className="mi-home-section-chart-chart">
            <VictoryChart
              //domainPadding={{ y: 10, x: 10 }}
              scale={{ x: "time", y: "linear" }}
              containerComponent={<VictoryVoronoiContainer />}
              padding={25}
              width={1000}
              height={470}
              //domainPadding={{ x: [10, -10], y: 5 }}
            >
              <VictoryAxis
                crossAxis
                width={400}
                height={400}
                domain={[0, 10]}
                labelComponent={<VictoryLabel angle={200} textAnchor="end" />}
                standalone={false}
                style={{
                  axis: { stroke: "#756f6a", fontSize: 10 },
                  axisLabel: { fontSize: 10, padding: 30 },
                  //grid: { stroke: "grey" },
                  ticks: { stroke: "grey", fontSize: 10 },
                  tickLabels: { fontSize: 10, padding: 5 },
                }}
              />
              <VictoryAxis
                dependentAxis
                crossAxis
                width={400}
                height={400}
                domain={[20, 55]}
                tickFormat={(x) => {
                  return x + "%";
                }}
                standalone={false}
                style={{
                  axis: { stroke: "#756f6a", fontSize: 10 },
                  axisLabel: { fontSize: 10, padding: 30 },
                  //grid: { stroke: "grey" },
                  ticks: { stroke: "grey", fontSize: 10 },
                  tickLabels: { fontSize: 10, padding: 5 },
                }}
              />
              <VictoryLine
                interpolation="natural"
                labels={({ datum }) => datum.y + "%"}
                labelComponent={
                  <VictoryTooltip
                    flyoutStyle={{ fill: "white" }}
                    style={{ fill: "black", fontSize: 10 }}
                    constrainToVisibleArea
                  />
                }
                data={data}
                style={{
                  data: { stroke: "#037fff", strokeWidth: 1 },
                  parent: { border: "1px solid #ccc" },
                }}
              />
              <VictoryScatter
                data={data}
                size={2}
                style={{ data: { fill: "#037fff" } }}
              />
            </VictoryChart>
          </div>
        </div>
      </div>

      <div style={{}} className="mi-home-section mi-home-section-art">
        <div className="mi-home-section-container">
          <div className="mi-home-section-rainbow mi-home-section-flex">
            <div className="" style={{ display: "flex", alignSelf: "center" }}>
              <FaPalette
                style={{ marginRight: "2rem" }}
                className="mi-home-section-icon mi-home-icon"
              />
            </div>
            <div>
              <div className="mi-home-section-title">
                DEVELOPERS, LEADERS, CREATIVES
              </div>
              <div className="mi-home-section-body" style={{}}>
                We want to paint the canvas of your ideas. Do you have some
                vision, some inspiration? We want to bring it into reality.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mi-home-cards">
        <Card className="mi-home-card">
          <FaDatabase
            size={"3rem"}
            color={"crimson"}
            className="mi-home-card-icon"
          />
          <div className="mi-home-card-title p-card-title">Data</div>
          <div>
            We help you control your data - we build SQL databases and data
            pipelines with MySQL and Postgres. We can also build predictive
            analytics using the latest in Data Science
          </div>
        </Card>
        <Card className="mi-home-card">
          <RiComputerFill
            size={"3rem"}
            color={"lightseagreen"}
            className="mi-home-card-icon"
          />
          <div className="mi-home-card-title p-card-title">Infrastructure</div>
          <div>
            We will build scalable infrastructure for you using the latest in
            DevOps technologies such as Docker, Kubernetes, and CI/CD
          </div>
        </Card>
        <Card className="mi-home-card">
          <RiPieChart2Fill
            size={"3rem"}
            color={"gold"}
            className="mi-home-card-icon"
          />
          <div className="mi-home-card-title p-card-title">Visualization</div>
          <div>
            We can create informative data visualizations with your data. Now
            you can see what's important for your organization.
          </div>
        </Card>
      </div>
    </Layout>
  );
}

export default Home;
