import React from "react";
import Header from "./Header";
import Footer from "./Footer";
// @ts-ignore
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineArrowUp } from "react-icons/ai";

function Layout(props: any) {
  return (
    <div className="mi-wrapper">
      <Header />
      <div id="scrolltop" />
      {props.children}

      <ToastContainer />
      <Footer />
      <ScrollButton />
    </div>
  );
}

type ScrollProps = {};
type ScrollState = {};

class ScrollButton extends React.Component<ScrollProps, ScrollState> {
  constructor(props: ScrollProps) {
    super(props);
  }

  scrollToTop() {
    document!
      .querySelector("#scrolltop")!
      .scrollIntoView({ behavior: "smooth" });
  }

  render() {
    return (
      <button
        title="Back to top"
        className="scroll"
        onClick={() => {
          this.scrollToTop();
        }}
      >
        <div className="arrow-up">
          <AiOutlineArrowUp size={"2rem"} />
        </div>
      </button>
    );
  }
}

export default Layout;
