import React, { useState, useEffect } from "react";
import axios from "axios";
import FsLightbox from "fslightbox-react";
import * as Icon from "react-feather";
import Layout from "../components/Layout";

function About() {
  const [toggler, setToggler] = useState(false);
  const [services, setServices] = useState([]);
  const [reviews, setReviews] = useState([]);

  return (
    <Layout>
      <div className="mi-child-img-filter">
        <img src="/images/detroit.png" />
      </div>
      <div className="mi-child-hero">
        <h1 className="mi-child-hero-title">Let Us Tell You About Ourselves</h1>
        <h3 className="mi-child-hero-subtitle">We aim to be the best at what we do</h3>
      </div>
      <div style={{ width: "80%", margin: "0 auto", color: "black" }}>
        <h2 style={{ color: "#037fff", textAlign: "center", marginTop: "-2rem", marginBottom: "2rem" }}>
          Our Story
        </h2>
        <div className="p-grid p-align-center">
          <div className="p-col">
            Founded in 2017, we've grown to encompass products throughout Michigan, America, and now internationally. Our goal is to deliver great services to our clients as well as innovative products.
          </div>
          <div className="p-col">
            <img
              style={{
                borderRadius: 100,
                height: 250,
                width: 500,
                objectFit: "cover",
              }}
              src="/images/datacenter.svg"
            />
          </div>
        </div>
        <div className="p-grid p-align-center">
          <div className="p-col">
            <img
              style={{
                borderRadius: 100,
                height: 250,
                width: 500,
                objectFit: "cover",
              }}
              src="/images/techconf.svg"
            />
          </div>
          <div className="p-col">
We consider ourselves both a people and a technology company. We strive to give amazing customer service alongside unique technical contributions. Our goal is to be responsive, knowledgable and above all helpful.
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default About;
